@tailwind base;
@tailwind components;

// Code syntax highlighting,
// powered by https://highlightjs.org
@import '~highlight.js/styles/a11y-light.css';

@import 'base';
@import 'navigation';

/** Use Fira Sans Typeface **/
@import "@fontsource/fira-sans/200-italic.css";
@import "@fontsource/fira-sans/200.css";
@import "@fontsource/fira-sans/300-italic.css";
@import "@fontsource/fira-sans/300.css";
@import "@fontsource/fira-sans/400-italic.css";
@import "@fontsource/fira-sans/400.css";
@import "@fontsource/fira-sans/500-italic.css";
@import "@fontsource/fira-sans/500.css";
@import "@fontsource/fira-sans/600-italic.css";
@import "@fontsource/fira-sans/600.css";
@import "@fontsource/fira-sans/700-italic.css";
@import "@fontsource/fira-sans/700.css";

/** Use Fontawesome Free **/
@import '~@fortawesome/fontawesome-free/css/all.css';

@tailwind utilities;
